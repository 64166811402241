.footer {
    &_title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        line-height: 20px;
        margin: 0 0 16px;
        color: #454545;
    }

    &_link {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        line-height: 20px;
        padding: 0 0 8px;
        color: #666;
        display: block;
    }
}
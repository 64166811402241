.link_footer_navigation {
    font-size: 12px;
    text-decoration: none;
    color: rgb(93, 108, 123);

    &:hover {
        color: #000;
    }
    &_active {
        color: #000;
    }
}